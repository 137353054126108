<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <AError
                :api="this.api"/>
         <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-row v-else>
            <v-col class="mx-auto" cols="12" sm="7">
                <v-btn
                color="primary"
                class="my-3"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
                <v-card class="pa-4">
                        <!-- Card title -->
                    <v-toolbar class="secondary ma-4">
                        <div>
                            <v-toolbar-title class="white--text text-h5">
                                <v-card-title>
                                    Activate Subscription
                                </v-card-title>                                
                            </v-toolbar-title>
                        </div>
                    </v-toolbar>
                    <!-- View company previous subscription -->
                    <!-- <div class="my-6 mx-4">
                        <router-link  :to="{name:'CompaniesSubscription',params:{id:this.$router.history.current.params.id}}">
                            <v-btn color="primary">
                                View all previous subscription
                            </v-btn>
                        </router-link>

                    </div> -->
                    <div class="mx-4 mt-6">
                        <v-autocomplete 
                        v-if="planNameOptions!=null" 
                        v-model="form.plan"
                        return-object :items="planNameOptions" outlined dense filled small-chips 
                        item-value="price_api" item-text="plan_name"
                         deletable-chips label="Plan Name">
                         <template v-slot:item="{item}">
                            <span>
                            {{ item.plan_name }} ( RM {{ item.price }} )
                            </span>
                         </template>
                         <template v-slot:selection="{item}">
                            {{ item.plan_name }} ( RM {{ item.price }} )
                         </template>
                        </v-autocomplete>

                        <v-autocomplete  label="Coupon (Optional)" item-text="code" item-value="code" v-if="couponOptions!=null" v-model="form.coupon" :items="couponOptions" outlined dense clearable>
                        </v-autocomplete>
                        <v-text-field label="Retention Minutes (If Applicable)" type="number" v-model="form.retentionMinute" outlined dense clearable suffix="minutes">
                        </v-text-field>
                        <!-- <v-text-field label="Coupon (Optional)" v-model="form.coupon" dense outlined>
                        </v-text-field> -->
                        <!-- Plan name and Coupon -->
                    </div>
                    

                    <div class="mx-4 mt-4" v-if="isDefaultPaymentShow">
                        <v-checkbox label="Use default payment method" v-model="form.defaultPaymentMethod">

                        </v-checkbox>
                    </div>
                    <div v-if="this.$store.getters.getFeatures.noinvoice.status == 1 || (this.$store.getters.getFeatures.noinvoice.beta == 1 && this.$store.getters.getRole.includes('developer'))">
                        <div class="mx-4 mt-4" v-if="isNoInvoice">
                            <v-checkbox label="Do not issue invoice"  v-model="form.invoice">
    
                            </v-checkbox>
                        </div>
                    </div>

                    <div class="mx-4 mt-4" >
                        <!-- Checkbox if user want or not schedule the subscription -->
                        <v-checkbox v-model="form.isWantScheduleSubscription" @click="resetScheduledSubscription" label="Do you want to schedule the subscription">

                        </v-checkbox>
                    </div>

                    <div class="mx-4 mt-4" v-if="form.isWantScheduleSubscription">
                        <v-text-field prepend-icon="mdi-calendar" dense outlined @click:prepend="redirectScheduleSubscription" @click="redirectScheduleSubscription" label="Scheduled Subscription" v-model="form.scheduledSubscription" >
                            
                        </v-text-field>

                        <v-dialog v-model="isScheduledSubscription" height="auto" width="auto">
                            <v-card class="pa-3">
                                <div class="my-1">
                                    <p class="d-flex justify-start mt-3">
                                        Scheduled Subscription
                                    </p>
                                    <div class="d-flex justify-start">
                                        <v-date-picker v-model="form.scheduledSubscription" :landscape="true" :reactive="true">
                                        </v-date-picker>
                                    </div>
                                </div>
                                <slot name="footer">
                                    <div class="d-flex justify-end my-3" color="white">
                                        <v-btn color="primary" plain @click="resetScheduledSubscription">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" @click="confirmScheduleSubscription">
                                            Confirm
                                        </v-btn>
                                    </div>
                                </slot>
                            </v-card>

                        </v-dialog>
                    </div>
                    <div>
                        <!-- Use default payment method -->
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn color="primary" plain @click="redirectBack">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" class="mx-3" @click="validateInput">
                            Confirm
                        </v-btn>
                        <!-- Activate button -->
                    </div>
                </v-card>
            </v-col>
        </v-row>
       
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isScheduledSubscription:false,
        form:{
            isWantScheduleSubscription:false,
            plan:null,
            coupon:null,
            scheduledSubscription:null,
            defaultPaymentMethod:false,
            retentionMinute:null,
            invoice:false,
        },
        planNameOptions:null,
        isNoInvoice:true,
        couponOptions:null,
        isPending:false,
        isDefaultPaymentShow:true,
        api:{
            isSuccesful:false,
            isLoading:false,
            isError:false,
            error:null,
            success:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='getAllPlan'){
                this.planNameOptions = resp.data;
            }
            if(resp.class=='getCouponBasedOnCategory'){
                this.couponOptions = resp.data;
            }
            if(resp.class=='activateManualSubscription'){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create a new plan";
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
                let availablePlanApi = this.fetchAvailablePlan();
                let availableCouponApi = this.fetchAvailableCoupon();
                this.$axios.all([
                    this.$api.fetch(availablePlanApi),
                    this.$api.fetch(availableCouponApi),

                ]); 

            },
            redirectBack(){
                this.$router.go(-1);
            },
        fetchAvailablePlan(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/plan/all';
            return tempApi;   
        },
        fetchAvailableCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/coupons/category?category[]=discount&category[]=minutes";
            return tempApi;
        },
        submit(){
            this.isPending = false;
            let createActivateSubscriptionApi = this.createActivateSubscription();
            this.$api.fetch(createActivateSubscriptionApi);
            },
            createActivateSubscription(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription/activate/company/"+this.$router.history.current.params.id;  
            let formData = new FormData;    
            formData.append('plan_id',this.form.plan.plan_id);
            formData.append('user_id',this.$store.getters.getUserId);
            if(this.form.retentionMinute !== 0 && this.form.retentionMinute !=null) {
                formData.append("extraMinute",this.form.retentionMinute);
            }
            formData.append('defaultPaymentMethod',(this.form.defaultPaymentMethod==false)?0:1);
            if(this.form.scheduledSubscription!=null)
            {
                formData.append('scheduleSubscribed','on');
                formData.append('date',this.form.scheduledSubscription);
            }
            // formData.append('defaultPaymentMethod','on');
            if(this.form.coupon!=null){
                formData.append('stripeCoupon',this.form.coupon)
            }
            formData.append('invoice',!this.form.invoice)
            tempApi.params = formData;
            return tempApi;
        },
        closeDetail(){
            this.$router.go(-1);
        },
        redirectScheduleSubscription(){
            this.isScheduledSubscription = true;
        },
        resetScheduledSubscription(){
            this.isScheduledSubscription = false;
            this.form.scheduledSubscription = null;
        },
        confirmScheduleSubscription(){
            this.isScheduledSubscription = false;
        },
        // checkCoupon(){
        //     this.isDefaultPaymentShow = true;
        //     // this.isNoInvoice = true;
        //     this.form.defaultPaymentMethod = false;
        //     if(this.form.coupon!=null){
        //         // this.isNoInvoice = false;
        //         this.isDefaultPaymentShow = false;
        //         this.form.defaultPaymentMethod = true;
        //     }
        // },
        validateInput(){
            this.isPending = true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
      
    }
}
</script>